import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import ReactFlagsSelect from "react-flags-select";
const languageList = ["GB", "PL", "TH", "CN", "RU", "DE", "FR", "ES"];

export default function LanguageChooserComponent() {
  const { locale, pathname, query } = useRouter();
  let language;
  switch (locale) {
    case "en":
      language = "GB";
      break;
    case "pl":
      language = "PL";
      break;
    case "zh":
      language = "CN";
      break;
    case "th":
      language = "TH";
      break;
    case "ru":
      language = "RU";
      break;
    case "de":
      language = "DE";
      break;
    case "fr":
      language = "FR";
      break;
    case "es":
      language = "ES";
      break;
    default:
      language = "GB";
  }
  const [lang, setlang] = useState(language);

  useEffect(() => {
    let loc;
    switch (lang) {
      case "GB":
        loc = "/en";
        break;
      case "PL":
        loc = "/pl";
        break;
      case "CN":
        loc = "/zh";
        break;
      case "TH":
        loc = "/th";
        break;
      case "RU":
        loc = "/ru";
        break;
      case "DE":
        loc = "/de";
        break;
      case "FR":
        loc = "/fr";
        break;
      case "ES":
        loc = "/es";
        break;
      default:
        loc = "/en";
    }
    if (window && loc !== `/${locale}`) {
      let path = pathname;
      if (query?.slug) {
        const slug = (query?.slug as string) || "";
        path = slug ? pathname.replace(/\[slug\]/giu, slug) : "";
      }
      if (query?.customerId) {
        const customerId = (query?.customerId as string) || "";
        path = customerId ? pathname.replace(/\[customerId\]/giu, customerId) : "";
      }
      if (query?.chatbotId) {
        const chatbotId = (query?.chatbotId as string) || "";
        path = chatbotId ? pathname.replace(/\[chatbotId\]/giu, chatbotId) : "";
      }
      const location = `${loc === "/en" ? "" : loc}${path}`;
      window.document.location = `${location}`;
    }
  }, [lang, locale, pathname, query]);

  return (
    <ReactFlagsSelect
      selectedSize={13}
      optionsSize={13}
      fullWidth={false}
      className="text-black"
      selectButtonClassName="text-black"
      countries={languageList}
      customLabels={{
        GB: "EN",
        FR: "FR",
        DE: "DE",
        PL: "PL",
        ES: "ES",
        RU: "RU",
        CN: "CN",
        TH: "TH",
      }}
      selected={lang}
      onSelect={(countryCode: string) => setlang(countryCode)}
      showSelectedLabel={true}
      showOptionLabel={true}
    />
  );
}
